/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { performLogin, performLogout } from '../../store/actions/auth';

import css from './LoginForm.module.scss';

class LoginForm extends React.Component {
  constructor(props) {
    super(props);

    this.closeForm = this.closeForm.bind(this);
    this.showForm = this.showForm.bind(this);
    this.updateUsername = this.updateUsername.bind(this);

    this.state = {
      showForm: false,
      formUsername: '',
      password: null,
    };
  }

  componentDidUpdate(prevProps) {
    const { username } = this.props;

    if (username !== prevProps.username) {
      this.closeForm();
    }
  }

  showForm() {
    const { username } = this.props;
    if (username != null) {
      this.setState({ showLogout: true });
    } else {
      this.setState({ showForm: true });
    }
  }

  closeForm() {
    this.setState({ showForm: false, showLogout: false });
  }

  updateUsername(event) {
    this.setState({
      formUsername: event.currentTarget.value,
    });
  }

  renderLogin() {
    const { doLogin } = this.props;
    const { formUsername, password } = this.state;
    return (
      <div className={css.modalContainer} onClick={this.closeForm}>
        <div className={css.modalCard} onClick={(e) => e.stopPropagation()}>
          <input
            onChange={this.updateUsername}
            type="text"
            value={formUsername}
            onKeyDown={(e) => e.key === 'Enter' && doLogin(formUsername, password) }
            ref={input => input && input.focus()}
          />
          <div className={css.formGroup}>
            <a onClick={() => doLogin(formUsername, password)}>submit</a>
            <a onClick={this.closeForm}>close</a>
          </div>
        </div>
      </div>
    );
  }

  renderLogout() {
    const { doLogout } = this.props;
    return (
      <div className={css.modalContainer} onClick={this.closeForm}>
        <div className={css.modalCard} onClick={(e) => e.stopPropagation()}>
          <div>Do you really want to log out?</div>
          <div className={css.formGroup}>
            <a onClick={doLogout}>Yes</a>
            <a onClick={this.closeForm}>cancel</a>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { username } = this.props;
    const {
      showForm, showLogout,
    } = this.state;

    return (
      <div>
        <a className={css.username} onClick={this.showForm}>{ username || 'Pick name' }</a>
        { showForm && this.renderLogin()}
        {
          (showLogout && username) && this.renderLogout()
        }
      </div>
    );
  }
}

LoginForm.propTypes = {
  username: PropTypes.string,
  doLogin: PropTypes.func.isRequired,
  doLogout: PropTypes.func.isRequired,
};

LoginForm.defaultProps = {
  username: null,
};

const mapStateToProps = (state) => ({
  username: state.auth.username,
});

const mapDispatchToProps = (dispatch) => ({
  doLogin: (username, password) => {
    dispatch(performLogin(username, password));
  },
  doLogout: () => {
    dispatch(performLogout());
  },
});

const ConnectedLoginForm = connect(
  mapStateToProps,
  mapDispatchToProps,
)(LoginForm);


export default ConnectedLoginForm;
