import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { checkLogin } from '../../store/actions/auth';
import css from './LoadingOverlay.module.scss';

class Overlay extends React.Component {
  constructor(props) {
    super(props);
    props.checkLogin();

    this.closeOverlay = this.closeOverlay.bind(this);
    this.state = {
      bufferedLoading: false,
      longTime: false,
    };
  }

  componentDidMount() {
    setTimeout(() => this.setState({ longTime: true }), 3000);
  }

  componentDidUpdate(prevProps) {
    const { loaded } = this.props;
    if (loaded && !prevProps.loaded) {
      // dont flash the overlay, close after 500ms
      setTimeout(this.closeOverlay, 500);
    }
  }

  closeOverlay() {
    this.setState({ bufferedLoading: true });
  }

  render() {
    const { bufferedLoading, longTime } = this.state;
    return (
      !bufferedLoading && (
        <div className={css.overlay}>
          <div className={css.content}>Loading</div>
          {longTime && <div className={css.content}>Please wait as server starts up!</div>}
        </div>
      )
    );
  }
}

Overlay.propTypes = {
  loaded: PropTypes.bool.isRequired,
  checkLogin: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  loaded: state.auth.loaded,
});

const mapDispatchToProps = (dispatch) => ({
  checkLogin: () => {
    dispatch(checkLogin());
  },
});

const LoadingOverlay = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Overlay);

export default LoadingOverlay;
