import React, { useState } from 'react';
import LoginForm from '../LoginForm/LoginForm';

import css from './Nav.module.scss';
import changelog from '../../json/changelog.json';

const Nav = () => {
  const [showChangelog, setShowChangelog] = useState(false);
  return (
    <div className={css.navContainer}>
      <div className={css.nav}>
        <div className={css.title}>
          <span>W I S S</span>
        </div>
        <div className={css.links}>
          <span 
            className={css.pointer}
            onClick={() => setShowChangelog(!showChangelog)}
          >
            changelog
          </span>
        </div>
        <div className={css.username}>
          <LoginForm />
        </div>
      </div>
      { showChangelog && (
        <div className={css.modalContainer} onClick={() => setShowChangelog(false)}>
          <div className={css.modalCard} onClick={(e) => e.stopPropagation()}>
            <div className={css.feedback}>Please submit feedback (word additions, features, bugs, anything!) to: bpan.feedback@gmail.com</div>
            {
              changelog.changes.map((val) => (
                <div className={css.changeGroup} key={`change_${val.date}`}>
                  <span className={css.date}>{val.date}</span>
                  <ul>
                    {val.notes.map((note) => (
                      <li>{note}</li>
                    ))}
                  </ul>
                </div>
              ))
            }
          </div>
        </div>
      )}
    </div>
  );
};

export default Nav;
