/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { connect } from 'react-redux';
import { Socket, Presence } from 'phoenix';
import PropTypes from 'prop-types';
// import CheckCircleIcon from '@material-ui/icons/CheckCircle';
// import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';

import { checkLogin, performLogin } from '../../store/actions/auth';

// import ClickerGame from '../../components/ClickerGame/ClickerGame';
import LoadingIcon from '../../components/LoadingIcon/LoadingIcon';
import DrawingBoard from '../../components/DrawingBoard/DrawingBoard';
import Chat from '../../components/Chat/Chat';
import css from './Rooms.module.scss';

const SOCKET_URL = process.env.NODE_ENV === 'development' ? 'ws://localhost:4000/socket' : 'wss://wiss-be.fly.dev/socket';

class Room extends React.Component {
  constructor(props) {
    super(props);

    this.connectSocket = this.connectSocket.bind(this);
    this.changeRoom = this.changeRoom.bind(this);
    this.handleChatKeyDown = this.handleChatKeyDown.bind(this);
    this.handleChatInput = this.handleChatInput.bind(this);
    this.toggleReady = this.toggleReady.bind(this);
    this.setStartGameCallback = this.setStartGameCallback.bind(this);
    this.setEndGameCallback = this.setEndGameCallback.bind(this);
    this.setGameStateCallback = this.setGameStateCallback.bind(this);

    this.state = {
      channel: null,
      socket: null,
      inGame: false,
      inRoom: [],
      gameOptions: [],
      selectedGame: '',
      userChannel: null,
      startGameCallback: () => {},
      endGameCallback: () => {},
      gameStateCallback: () => {},
    };
  }

  componentDidUpdate(prevProps) {
    // todo: change font proxia nova
    const { username, token, match } = this.props;
    // do stuff when the user logs in
    const { channel, socket, userChannel } = this.state;
    if (!username) {
      if (socket && socket.conn != null) {
        channel.leave();
        userChannel.leave();
        socket.disconnect();
      }
    }
    if (username && username !== prevProps.username) {
      // remove rooms
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        inRoom: [],
      });

      if (socket && socket.conn != null) {
        channel.leave();
        userChannel.leave();
        socket.disconnect();
      }

      // change room
      this.connectSocket(token);
    }
    // check if the user changed room
    if (match.params.room !== prevProps.match.params.room) {
      this.changeRoom();
    }
  }

  setEndGameCallback(fnc) {
    this.setState({
      endGameCallback: fnc,
    });
  }

  setStartGameCallback(fnc) {
    this.setState({
      startGameCallback: fnc,
    });
  }

  setGameStateCallback(fnc) {
    this.setState({
      gameStateCallback: fnc,
    });
  }

  connectSocket(token) {
    const { match, username } = this.props;
    const { startGameCallback, endGameCallback, gameStateCallback } = this.state;

    // only connect to socket once
    const connectedSocket = new Socket(SOCKET_URL, { params: { token } });
    connectedSocket.connect();

    const connectedChannel = connectedSocket.channel(`room:${match.params.room || 'lobby'}`, {});
    connectedChannel.join()
      // .receive('ok', (resp) => { console.log('Joined successfully', resp); })
      .receive('error', (resp) => { console.log('Unable to join', resp); connectedChannel.leave(); }); // this stops it from retrying


    connectedChannel.on('get_room_state', (payload) => {
      this.setState({
        inGame: payload.state === 'in_game',
        gameOptions: payload.game_options,
        selectedGame: payload.selected_game,
      });
      if (payload.state === 'in_game') {
        connectedChannel.push('update_ready', {
          state: true,
        });
      }
    });

    connectedChannel.on('start_game', (payload) => {
      connectedChannel.push('update_ready', {
        state: false,
      });
      this.setState({
        inGame: true,
      });
      startGameCallback(payload);
    });

    connectedChannel.on('end_game', (payload) => {
      console.log('game ended!');
      connectedChannel.push('update_ready', {
        state: false,
      });
      this.setState({
        inGame: false,
      });
      endGameCallback(payload);
    });

    // connectedChannel.on('game_timer', (payload) => {
    //   console.log(payload);
    // });

    const presence = new Presence(connectedChannel);
    // receive presence data from server
    presence.onSync(() => {
      this.setState({
        inRoom: presence.list().map((e) => ({
          user: e.user,
          ready: e.metas[0].ready,
          joined: e.metas[0].room_join,
        })),
      });
    });

    const userChannel = connectedSocket.channel(`user:${username}`, {});
    userChannel.join();
    userChannel.on('game_state', (payload) => {
      gameStateCallback(payload);
    });

    connectedChannel.push('get_room_state', {});
    this.setState({
      channel: connectedChannel,
      socket: connectedSocket,
      userChannel,
    });
  }

  changeRoom() {
    const { channel, socket, userChannel } = this.state;
    const { match } = this.props;

    if (channel) {
      channel.leave();
      userChannel.leave();
    }
    if (socket && socket.conn != null) {
      const newChannel = socket.channel(`room:${match.params.room || 'lobby'}`, {});
      newChannel.join()
        // .receive('ok', (resp) => { console.log('Joined successfully', resp); })
        .receive('error', (resp) => { console.log('Unable to join', resp); });

      const presence = new Presence(newChannel);
      // receive presence data from server
      presence.onSync(() => {
        this.setState({
          inRoom: presence.list().map((e) => ({
            user: e.user,
            ready: e.metas[0].ready,
            joined: e.metas[0].room_join,
          })),
        });
      });
      this.setState({
        channel: newChannel,
      });
    } else {
      console.log('NO CONNECTED SOCKET?');
    }
  }

  handleChatKeyDown(e) {
    const { channel, chatInput } = this.state;
    if (e.key === 'Enter') {
      this.setState({
        chatInput: '',
      });
      channel.push('new_msg', { body: chatInput });
    }
  }

  handleChatInput(e) {
    this.setState({
      chatInput: e.currentTarget.value,
    });
  }

  toggleReady() {
    const { username } = this.props;
    const { channel, inRoom } = this.state;
    const currentUser = inRoom.filter((val) => val.user === username)[0]
    channel.push('update_ready', {
      state: !currentUser.ready,
    });
  }

  selectGame(selectedGame) {
    this.setState({
      selectedGame,
    });
  }

  render() {
    const { match, username } = this.props;
    const {
      inGame,
      inRoom,
      // gameOptions,
      channel,
      selectedGame,
    } = this.state;
    const userReady = inRoom.filter((val) => val.user === username).length && inRoom.filter((val) => val.user === username)[0].ready;

    return (
      <div className={`${css.fullPage} ${css.mainContainer}`}>
        {/* overlay to prompt user sign-in */}
        {!username && (
          <div className={css.overlay}>
            <div className={css.content}>
              {`Please login to see ${match.params.room || 'lobby'} details.`}
            </div>
          </div>
        )}
        <div className={css.roomContainer}>
          <div className={css.content2}>
            <div className={css.namesContainer}>
              {inRoom.sort(
                (a, b) => a.joined > b.joined,
              ).map((val) => (
                <div
                  className={`${css.nameGroup} ${val.ready ? css.ready : ''}`}
                  key={val.user}
                >
                  <div className={css.nameGroupContent}>
                    {/* {val.ready ? <CheckCircleIcon /> : <RadioButtonUncheckedIcon />} */}
                    <span className={css.name}>{val.user}</span>
                  </div>
                </div>
              ))}
            </div>
            <div className={css.gameContainer}>
              {/*
              
              need to add:
              profile pic/emoji?
              countdown on room ready when 2 or more players
              event to select game, ready game at the same time
                show game selection - for now just show number selected
                unready or click game again = remove selection
              when game is ready, tally up votes for users, randomly select game if tied

            
              */}
              

              {!inGame && (
                <div className={css.gameOverlay}>
                  <div className={css.gameChooser}>
                    {/* {gameOptions.map((val) => (
                      <div className={css.gameOption} onClick={() => this.selectGame(val)}>
                        <span>{val}</span>
                        <div className={css.hoverBorder} />
                      </div>
                    ))} */}
                    {selectedGame}
                  </div>
                  <span className={css.title}>Waiting for players!</span>
                  <span><LoadingIcon width={32} height={32} /></span>
                </div>
              )}
              {/* note: we preload the div to bind callback before countdown */}
              {/* do the selectedGame logic here */}
              <DrawingBoard
                channel={channel}
                setStartGameCallback={this.setStartGameCallback}
                setEndGameCallback={this.setEndGameCallback}
                setGameStateCallback={this.setGameStateCallback}
              />
              {/* <ClickerGame
                  toggleReady={this.toggleReady}
                  // maybe we dont need this
                  setStartGameCallback={this.setStartGameCallback}
                /> */}
            </div>
          </div>
          <div className={css.chat}>
            <Chat channel={channel} toggleReady={this.toggleReady} inGame={inGame} userReady={userReady} />
            {/* onClick={(val.user === username && !inGame) ? this.toggleReady : null} */}
          </div>
        </div>
      </div>
    );
  }
}

Room.propTypes = {
  username: PropTypes.string,
  token: PropTypes.string,
  match: PropTypes.shape({
    params: PropTypes.object,
  }).isRequired,
};

Room.defaultProps = {
  username: null,
  token: null,
};


const mapStateToProps = (state) => ({
  username: state.auth.username,
  token: state.auth.token,
});

const mapDispatchToProps = (dispatch) => ({
  checkLogin: () => {
    dispatch(checkLogin());
  },
  performLogin: (username, password) => {
    dispatch(performLogin(username, password));
  },
});

const Rooms = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Room);

export default Rooms;
