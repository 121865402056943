// action types
export const CHECKED_LOGIN = 'CHECKED_LOGIN';
export const PERFORM_LOGIN = 'PERFORM_LOGIN';
export const RECEIVE_LOGIN = 'RECEIVE_LOGIN';

const API_URL = process.env.NODE_ENV === 'development' ? 'http://localhost:4000/api' : 'https://wiss-be.fly.dev/api';

export function receiveLogin(username, token) {
  return {
    type: RECEIVE_LOGIN,
    username,
    token,
  };
}

function checkedLogin() {
  return {
    type: CHECKED_LOGIN,
  };
}

export function checkLogin() {
  return (dispatch) => fetch(`${API_URL}/login`, {
    credentials: 'include',
  })
    .then((response) => response.json())
    .then((json) => {
      dispatch(receiveLogin(json ? json.username : null, json ? json.token : null));
      dispatch(checkedLogin());
    });
}

export function performLogin(username, password) {
  return (dispatch) => fetch(`${API_URL}/login`, {
    method: 'POST',
    credentials: 'include',
    body: JSON.stringify({
      username,
      password,
    }),
  })
    .then((response) => response.json())
    .then((json) => dispatch(receiveLogin(json.username, json.token)));
}

export function performLogout() {
  return (dispatch) => fetch(`${API_URL}/logout`, {
    credentials: 'include',
  })
    .then(() => dispatch(receiveLogin(null, null)));
}
