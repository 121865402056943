/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { connect } from 'react-redux';
import {
  Editor, EditorState, RichUtils, getDefaultKeyBinding, ContentState,
} from 'draft-js';
import PropTypes from 'prop-types';
import { Presence } from 'phoenix';

import css from './Chat.module.scss';


function customBinding(e) {
  if (e.key === 'Enter' && !e.shiftKey) {
    return 'send-msg';
  }
  // console.log(getDefaultKeyBinding(e));
  return getDefaultKeyBinding(e);
}

class Chat extends React.Component {
  constructor(props) {
    super(props);

    this.messageContainer = React.createRef();
    this.onChange = (editorState) => this.setState({ editorState });
    this.handleKeyCommand = this.handleKeyCommand.bind(this);

    this.state = {
      editorState: EditorState.createEmpty(),
      messages: [],
      prevUser: '',
    };
  }

  componentDidUpdate(prevProps) {
    const { channel, username } = this.props;

    if (channel && (!prevProps.channel || prevProps.channel.state === 'closed')) {
      channel.on('new_msg', (payload) => {
        this.setState((prevState) => ({
          messages: prevState.messages.concat([{
            id: Date.now(),
            message: payload.body,
            timestamp: new Date(payload.timestamp),
            user: prevState.prevUser === payload.user ? null : payload.user,
            self: username === payload.user,
          }]),
          prevUser: payload.user,
        }));
        const objDiv = this.messageContainer.current;
        if (objDiv.scrollTop + objDiv.clientHeight + 100 >= objDiv.scrollHeight) {
          objDiv.scrollTop = objDiv.scrollHeight;
        }
      });

      channel.on('new_event', (payload) => {
        this.setState((prevState) => ({
          messages: prevState.messages.concat(payload),
          prevUser: '',
        }));
        const objDiv = this.messageContainer.current;
        if (objDiv.scrollTop + objDiv.clientHeight + 50 >= objDiv.scrollHeight) {
          objDiv.scrollTop = objDiv.scrollHeight;
        }
      });

      const presence = new Presence(channel);

      // detect if user has joined for the 1st time or from another tab/device
      presence.onJoin((id, current, newPres) => {
        if (!current) {
          this.setState((prevState) => ({
            messages: prevState.messages.concat([{
              id: Date.now(),
              message: `${newPres.user} has joined the room.`,
              timestamp: new Date(),
              user: null,
              event: true,
            }]),
          }));
          const objDiv = this.messageContainer.current;
          if (objDiv.scrollTop + objDiv.clientHeight + 50 >= objDiv.scrollHeight) {
            objDiv.scrollTop = objDiv.scrollHeight;
          }
        }
      });

      // detect if user has left from all tabs/devices, or is still present
      presence.onLeave((id, current, leftPres) => {
        if (current.metas.length === 0) {
          this.setState((prevState) => ({
            messages: prevState.messages.concat([{
              id: Date.now(),
              message: `${leftPres.user} has left the room.`,
              timestamp: new Date(),
              user: null,
              event: true,
            }]),
          }));
          const objDiv = this.messageContainer.current;
          if (objDiv.scrollTop + objDiv.clientHeight + 50 >= objDiv.scrollHeight) {
            objDiv.scrollTop = objDiv.scrollHeight;
          }
        }
      });

      // clear msgs
      if (username) {
        this.setState({
          messages: [],
        });
      }
    }
  }

  handleKeyCommand(command, editorState) {
    const { channel } = this.props;
    if (command === 'send-msg') {
      const msg = editorState.getCurrentContent().getPlainText().trim();
      if (channel && msg) {
        channel.push('new_msg', { body: msg });
      }

      // clear the editor
      const newEditorState = EditorState.push(editorState, ContentState.createFromText(''));
      // **DO NOT REMOVE THIS STEP**
      const focusedEditorState = EditorState.moveFocusToEnd(newEditorState);
      this.onChange(focusedEditorState);
      return 'handled';
    }
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      this.onChange(newState);
      return 'handled';
    }
    // default behavior
    return 'not-handled';
  }

  render() {
    const { channel, inGame, toggleReady, userReady } = this.props;
    const { editorState, messages } = this.state;
    return (
      <div className={css.chatContainer}>
        {/* <div>Please consider submitting feedback to bpan.feedback(at)gmail.com !!</div> */}
        <div className={css.messageContainer} ref={this.messageContainer}>
          {messages.map((val) => (
            val.event ? (
              <div className={css.message} key={val.id}>
                <div className={css.event}>{val.message}</div>
              </div>
            ) : (
              <div className={`${css.message} ${val.self ? css.right : ''}`} key={val.id}>
                {val.user && <div className={css.user}>{val.user}</div>}
                <span className={css.bubble}>{val.message}</span>
              </div>
            )
          ))}
        </div>
        <div className={css.chatInputContainer}>
          <div className={css.chatInput}>
            <Editor
              editorState={editorState}
              onChange={this.onChange}
              keyBindingFn={customBinding}
              handleKeyCommand={this.handleKeyCommand}
              ref={(input) => (channel && channel.state !== 'closed' && input) && input.focus()}
            />
          </div>
          <div
            className={!inGame ? `${css.ready} ${userReady ? css.active : ''}` : `${css.ready} ${css.disabled}`}
            onClick={(!inGame) ? toggleReady : null}
          >
            ready
          </div>
        </div>
      </div>
    );
  }
}

Chat.propTypes = {
  channel: PropTypes.shape({
    push: PropTypes.func.isRequired,
    state: PropTypes.string.isRequired,
  }),
  username: PropTypes.string,
};

Chat.defaultProps = {
  channel: null,
  username: null,
};
const mapStateToProps = (state) => ({
  username: state.auth.username,
});

const connectedChat = connect(
  mapStateToProps,
)(Chat);

export default connectedChat;
