import {
  CHECKED_LOGIN,
  RECEIVE_LOGIN,
} from '../actions/auth';

const initialState = {
  username: null,
  loaded: false,
  socket: null,
  channel: null,
};

// function connectSocket(token) {
//   const { match } = this.props;

//   // only connect to socket once
//   let connectedSocket = new Socket(SOCKET_URL, {params: {token}});
//   connectedSocket.connect();

// }

function auth(state = initialState, action) {
  switch (action.type) {
    case CHECKED_LOGIN:
      return { ...state, loaded: true };
    case RECEIVE_LOGIN:
      // todo: migrate socket to redux?
      // if (socket) {
      //   socket.disconnect();
      // }
      // if (action.token) {
      //   const connectedSocket = new Socket(SOCKET_URL, {params: {token}});
      // }
      return { ...state, username: action.username, token: action.token };
    default:
      return state;
  }
}

export default auth;
