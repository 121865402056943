import 'es6-shim';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import {
  HashRouter, Route, Switch, Redirect,
} from 'react-router-dom';
import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';

import './index.css';
import reducer from './store/reducers';
import Rooms from './pages/Rooms/Rooms';
import P404 from './pages/404';
import * as serviceWorker from './serviceWorker';

import Nav from './components/Nav/Nav';
import LoadingOverlay from './components/LoadingOverlay/LoadingOverlay';

// eslint-disable-next-line no-console
const store = createStore(
  reducer,
  applyMiddleware(
    thunkMiddleware,
  ),
);

ReactDOM.render(
  <Provider store={store}>
    <LoadingOverlay />
    <Nav />
    <HashRouter>
      <Switch>
        <Route exact path="/">
          <Redirect to="/rooms" />
        </Route>
        <Route path="/rooms/:room?" component={Rooms} />
        {/* <Route exact path={URL_BASE} component={Index} /> */}
        <Route component={P404} />
      </Switch>
    </HashRouter>
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
