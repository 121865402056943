/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import css from './DrawingBoard.module.scss';

class DrawingBoard extends React.Component {
  constructor(props) {
    super(props);

    this.canvas = React.createRef();
    this.canvas2 = React.createRef();
    this.draw = this.draw.bind(this);
    this.drawLine = this.drawLine.bind(this);
    this.calcPos = this.calcPos.bind(this);
    this.canvasMouseDown = this.canvasMouseDown.bind(this);
    this.canvasMouseUp = this.canvasMouseUp.bind(this);
    this.rightClick = this.rightClick.bind(this);
    this.scrollChange = this.scrollChange.bind(this);
    this.setColor = this.setColor.bind(this);

    this.state = {
      drawingUser: null,
      color: '#000',
      chosenWord: '',
      chooseWords: false,
      mousedown: false,
      operation: 'draw',
      pos: { x: 0, y: 0 },
      lineWidthPen: 5,
      lineWidthErase: 15,
      words: [],
    };
  }

  componentDidMount() {
    const { setStartGameCallback, setEndGameCallback, setGameStateCallback } = this.props;
    // use this.props because doesnt update
    setStartGameCallback((payload) => {
      // eslint-disable-next-line react/destructuring-assignment
      this.props.channel.push('start_pictionary', {});
      this.setState({
        drawingUser: payload.user,
      });
    });
    setGameStateCallback((payload) => {
      // eslint-disable-next-line react/destructuring-assignment
      this.setState({
        drawingUser: payload.drawingUser,
      });
      const imageObj = new Image();
      imageObj.onload = () => {
        this.canvas.current.getContext('2d').drawImage(imageObj, 0, 0);
      };
      imageObj.onload = imageObj.onload.bind(this);
      imageObj.src = payload.saveState;
    });
    setEndGameCallback((payload) => {
      const ctx = this.canvas.current.getContext('2d');
      ctx.clearRect(0, 0, this.canvas.current.width, this.canvas.current.height);
      // reset the state too
      this.setState({
        drawingUser: null,
        color: '#000',
        chosenWord: '',
        chooseWords: false,
        mousedown: false,
        operation: 'draw',
      });
    });
  }

  componentDidUpdate(prevProps) {
    const { channel, username } = this.props;
    const { drawingUser } = this.state;

    if (channel && (!prevProps.channel || prevProps.channel.state === 'closed')) {
      channel.on('clear_screen', () => {
        const ctx = this.canvas.current.getContext('2d');
        ctx.clearRect(0, 0, this.canvas.current.width, this.canvas.current.height);
      });
      channel.on('get_game_state', (payload) => {
        this.setState({
          drawingUser: payload.allowedUsers[0],
          chosenWord: payload.selectedWord,
        });
      });
      channel.on('request_game_state', () => {
        // eslint-disable-next-line react/destructuring-assignment
        if (this.state.drawingUser === username) {
          channel.push('request_game_state', {
            drawingUser,
            saveState: this.canvas.current.toDataURL(),
          });
        }
      });
      channel.on('pictionary_words', (payload) => {
        this.setState({
          words: payload.words,
          chosenWord: payload.words[0],
          chooseWords: true,
        });
        setTimeout(() => this.setState({ chooseWords: false }), 10000);
      });

      channel.on('update_canvas', (payload) => {
        const ctx = this.canvas.current.getContext('2d');
        this.drawLine(ctx,
          payload.x1,
          payload.y1,
          payload.x2,
          payload.y2,
          payload.color,
          payload.lineWidth,
          payload.operation);
      });

      channel.on('update_canvas2', (payload) => {
        const ctx2 = this.canvas2.current.getContext('2d');
        ctx2.clearRect(0, 0, this.canvas2.current.width, this.canvas2.current.height);
        if (payload.operation === 'erase') {
          this.drawLine(ctx2, payload.x1, payload.y1, payload.x2, payload.y2, '#000', payload.lineWidth + 3, 'draw');
        }
        this.drawLine(ctx2,
          payload.x1,
          payload.y1,
          payload.x2,
          payload.y2,
          payload.color,
          payload.lineWidth,
          payload.operation);
      });

      channel.push('get_game_state', {});
    }
  }

  setColor(color, operation) {
    this.setState({
      color,
      operation,
    });
  }

  pickWord(word) {
    const { channel } = this.props;

    channel.push('pick_word', {
      word,
    });
    this.setState({
      chosenWord: word,
      chooseWords: false,
    });
  }

  calcPos(e) {
    const rect = this.canvas.current.getBoundingClientRect();
    const x = (e.clientX === undefined ? e.touches[0].clientX : e.clientX) - rect.left;
    const y = (e.clientY === undefined ? e.touches[0].clientY : e.clientY) - rect.top;
    return { x, y };
  }

  canvasMouseDown(e) {
    const { channel, username } = this.props;
    const {
      color,
      drawingUser,
      operation,
      lineWidthPen,
      lineWidthErase,
    } = this.state;
    // save processing for drawing user only
    if (drawingUser === username) {
      const newPos = this.calcPos(e);

      this.setState({
        mousedown: true,
        pos: newPos,
        operation,
      });
      channel.push('update_canvas', {
        color,
        x1: newPos.x,
        y1: newPos.y,
        x2: newPos.x,
        y2: newPos.y,
        lineWidth: operation === 'draw' ? lineWidthPen : lineWidthErase,
        // saveState: this.canvas.current.toDataURL(),
        operation,
      });
    }
  }

  canvasMouseUp() {
    this.setState({
      mousedown: false,
    });
  }

  draw(e) {
    const { channel, username } = this.props;
    const {
      color,
      drawingUser,
      mousedown,
      pos,
      operation,
      lineWidthPen,
      lineWidthErase,
    } = this.state;

    // save processing for drawing user only
    if (drawingUser === username) {
      const newPos = this.calcPos(e);

      // save bandwidth, don't push
      // const ctx2 = this.canvas2.current.getContext('2d');
      // ctx2.clearRect(0, 0, this.canvas2.current.width, this.canvas2.current.height);
      // if (operation === 'erase') {
      //   this.drawLine(ctx2, newPos.x, newPos.y, newPos.x, newPos.y, '#000', lineWidthErase + 3, 'draw');
      // }
      // this.drawLine(ctx2,
      //   newPos.x,
      //   newPos.y,
      //   newPos.x,
      //   newPos.y,
      //   color,
      //   operation === 'draw' ? lineWidthPen : lineWidthErase,
      //   operation);
      if (e.buttons !== 1 || operation === 'erase') {
        channel.push('update_canvas2', {
          color,
          x1: newPos.x,
          y1: newPos.y,
          x2: newPos.x,
          y2: newPos.y,
          operation,
          lineWidth: operation === 'draw' ? lineWidthPen : lineWidthErase,
        });
      }
      this.setState({ pos: newPos });

      // todo: make this more robust
      if (!mousedown) return;

      channel.push('update_canvas', {
        color,
        x1: pos.x,
        y1: pos.y,
        x2: newPos.x,
        y2: newPos.y,
        lineWidth: operation === 'draw' ? lineWidthPen : lineWidthErase,
        operation,
        // saveState: this.canvas.current.toDataURL(),
      });
    }
  }

  // eslint-disable-next-line class-methods-use-this
  drawLine(ctx, x1, y1, x2, y2, color, lineWidth, operation) {
    // ctx.translate(0.5, 0.5);
    if (operation === 'draw') {
      ctx.globalCompositeOperation = 'source-over';
      ctx.strokeStyle = color;
    }
    if (operation === 'erase') {
      ctx.globalCompositeOperation = 'destination-out';
    }

    ctx.lineCap = 'round';
    ctx.lineWidth = lineWidth;

    ctx.beginPath(); // begin
    ctx.moveTo(x1, y1); // from
    // ctx.lineTo(x2, y2); // to
    ctx.quadraticCurveTo((x1 + x2) / 2, (y1 + y2) / 2, x2, y2);
    ctx.stroke(); // draw it!
  }

  rightClick(e) {
    e.preventDefault();
    const { color, drawingUser, operation, lineWidthPen, lineWidthErase } = this.state;
    const { channel, username } = this.props;

    if (drawingUser === username) {
      const newPos = this.calcPos(e);

      this.setState({
        mousedown: true,
        pos: newPos,
        operation: 'erase',
      });
      channel.push('update_canvas', {
        color,
        operation: 'erase',
        lineWidth: operation === 'draw' ? lineWidthPen : lineWidthErase,
        x1: newPos.x,
        y1: newPos.y,
        x2: newPos.x,
        y2: newPos.y,
        // saveState: this.canvas.current.toDataURL(),
      });
    }
  }

  scrollChange(e) {
    e.persist();
    const { channel } = this.props;
    const {
      color,
      operation,
      pos,
      lineWidthPen,
      lineWidthErase,
    } = this.state;
    let originalLineWidth = 3;
    let newLineWidth = 3;

    if (operation === 'erase') {
      newLineWidth = e.deltaY > 0
        ? Math.min(50, lineWidthErase + 2) : Math.max(3, lineWidthErase - 2);
      originalLineWidth = lineWidthErase;
    }
    if (operation === 'draw') {
      newLineWidth = e.deltaY > 0 ? Math.min(20, lineWidthPen + 2) : Math.max(3, lineWidthPen - 2);
      originalLineWidth = lineWidthPen;
    }
    if (originalLineWidth !== newLineWidth) {
      if (operation === 'erase') {
        this.setState((prevProps) => ({
          lineWidthErase: e.deltaY > 0
            ? Math.min(50, prevProps.lineWidthErase + 2)
            : Math.max(3, prevProps.lineWidthErase - 2),
        }));
      }
      if (operation === 'draw') {
        this.setState((prevProps) => ({
          lineWidthPen: e.deltaY > 0
            ? Math.min(20, prevProps.lineWidthPen + 2) : Math.max(3, prevProps.lineWidthPen - 2),
        }));
      }

      // save bandwidth, don't push
      // const ctx2 = this.canvas2.current.getContext('2d');
      // ctx2.clearRect(0, 0, this.canvas2.current.width, this.canvas2.current.height);
      // if (operation === 'erase') {
      //   this.drawLine(ctx2, pos.x, pos.y, pos.x, pos.y, '#000', lineWidthErase + 3, 'draw');
      // }
      // this.drawLine(ctx2,
      //   pos.x,
      //   pos.y,
      //   pos.x,
      //   pos.y,
      //   color,
      //   operation === 'draw' ? lineWidthPen : lineWidthErase,
      //   operation);
      channel.push('update_canvas2', {
        color,
        x1: pos.x,
        y1: pos.y,
        x2: pos.x,
        y2: pos.y,
        operation,
        lineWidth: operation === 'draw' ? lineWidthPen : lineWidthErase,
      });
    }
  }

  /*
    TODO:
    - ROOM STUFF:
      - room master for timer? kick?
      - fix random number generator -> take turns instead (maybe use a list) DONE
        - auto cycle through the list once everyone is ready once DONE
        - HANDLE DRAWER LEAVING
      - ***send game-state if refresh*** ok, test this
      - investigate game starting multiple times (maybe use a list of process ids)
      - expose words when ending, DONE hint near end? todo
      - game switching, other stuff w transition
      - fix regex DONE

      picitonary cycle:
      - all user ready -> countdown then start in_game_pictionary
        - in_game_pictionary -> keep track of user_ids (to draw next)
        - pop from the list when user is drawing or disappeared when its their turn (hd(), tl())
        - play game w popped user AND present in room
        - wait 3 sec after game ends todo
      - once no more users in list and in_game_pictionary, end_game

    https://skribbl-io.net/?s=word+list
    https://whatsetup.com/skribbl-io-custom-words/

    - FE stuff:
      - screen sizing**
      - colors, pen size, draw, erase ok

    # hide chat if screen width is less than 720 + 150 = 870 px
    # scrolling on height
  */
  render() {
    const {
      color,
      chosenWord,
      chooseWords,
      drawingUser,
      words,
    } = this.state;
    const { username } = this.props;
    return (
      <div className={css.drawingBoardContainer}>
        {chooseWords && (
          <div className={css.overlay}>
            <div className={css.box}>
              <div className={css.title}>Choose a word to draw:</div>
              {words.map((val) => (
                <div className={css.word} key={`word_${val}`} onClick={() => this.pickWord(val)}>{val}</div>
              ))}
            </div>
          </div>
        )}
        {drawingUser === username && (
          <div className={css.wordReminder}>{`The word you are drawing is: ${chosenWord}`}</div>
        )}
        <div className={css.drawingBoard}>
          <div className={css.toolbar}>
            <div className={css.item}>
              <div
                className={`${css.circle} ${css.blackbg}`}
                onClick={() => this.setColor('black', 'draw')}
              />
            </div>
            <div className={css.item}>
              <div
                className={`${css.circle} ${css.redbg}`}
                onClick={() => this.setColor('red', 'draw')}
              />
            </div>
            <div className={css.item}>
              <div
                className={`${css.circle} ${css.orangebg}`}
                onClick={() => this.setColor('orange', 'draw')}
              />
            </div>
            <div className={css.item}>
              <div
                className={`${css.circle} ${css.yellowbg}`}
                onClick={() => this.setColor('yellow', 'draw')}
              />
            </div>
            <div className={css.item}>
              <div
                className={`${css.circle} ${css.greenbg}`}
                onClick={() => this.setColor('green', 'draw')}
              />
            </div>
            <div className={css.item}>
              <div
                className={`${css.circle} ${css.bluebg}`}
                onClick={() => this.setColor('blue', 'draw')}
              />
            </div>
            <div className={css.item}>
              <div
                className={`${css.circle} ${css.purplebg}`}
                onClick={() => this.setColor('purple', 'draw')}
              />
            </div>
            <div className={css.item}>
              <div
                className={`${css.circle} ${css.erase}`}
                onClick={() => this.setColor(color, 'erase')}
              />
            </div>
          </div>
          <div
            className={`${css.canvasContainer} ${drawingUser !== username ? css.notDrawing : ''}`}
            onMouseDown={this.canvasMouseDown}
            onTouchStart={(e) => { e.preventDefault(); this.canvasMouseDown(e); }}
            onMouseMove={this.draw}
            onTouchMove={(e) => { e.preventDefault(); this.draw(e); }}
            onMouseUp={this.canvasMouseUp}
            onMouseOut={(e) => { if (e.buttons !== 1) this.setState({ mousedown: false }); }}
            onBlur={(e) => { if (e.buttons !== 1) this.setState({ mousedown: false }); }}
            onMouseEnter={(e) => { if (e.buttons !== 1) this.setState({ mousedown: false }); }}
            onTouchEnd={(e) => { e.preventDefault(); this.canvasMouseUp(e); }}
            // onContextMenu={this.rightClick}
            onWheel={this.scrollChange}
            role="presentation"
          >
            {/* todo: figure out dynamic width? */}
            <canvas className={css.canvas} ref={this.canvas} height="720" width="690" />
            <canvas className={css.canvas2} ref={this.canvas2} height="720" width="690" />
            {/* visible for drawing users only, absolute position */}
            <div className={css.controls}>controls: left click - draw, scroll - change pen size, tab - toggle chat</div>
          </div>
        </div>
      </div>
    );
  }
}

DrawingBoard.propTypes = {
  channel: PropTypes.shape({
    push: PropTypes.func.isRequired,
    state: PropTypes.string.isRequired,
    on: PropTypes.func.isRequired,
  }),
  setStartGameCallback: PropTypes.func.isRequired,
  setEndGameCallback: PropTypes.func.isRequired,
  setGameStateCallback: PropTypes.func.isRequired,
  username: PropTypes.string,
};

DrawingBoard.defaultProps = {
  channel: null,
  username: '',
};

const mapStateToProps = (state) => ({
  username: state.auth.username,
});

const connected = connect(
  mapStateToProps,
)(DrawingBoard);

export default connected;

// https://jsfiddle.net/richardcwc/d2gxjdva/ draw/erase
// https://stackoverflow.com/questions/2368784/draw-on-html5-canvas-using-a-mouse
